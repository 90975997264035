import Tabs from './tabs.js';
import LinksTracker from './linkstracker';
import lazyframe from 'lazyframe';

class Global {
  constructor(options) {
    var defaults = {};
    this.params = Object.assign({}, defaults, options || {});
    this.run();
  }

  run() {
    // this.menu();
    this.searchBoxes();
    this.tabs();
    new LinksTracker();
    this.lazyLoadVideos();
    this.sharingTools();
  }

  menu() {

    const burgerOne = document.querySelector(".burger-one");
    const burgerTwo = document.querySelector(".burger-two");
    const menu = document.querySelector(".menu-burger-general");

    if (burgerOne) {
      burgerOne.addEventListener("click", function () {
        burgerOne.classList.toggle('expand');
        burgerTwo.classList.toggle('expand');
        menu.classList.toggle('expand');
        document.body.classList.toggle('expanding');
      });
    }

    if (burgerTwo) {
      burgerTwo.addEventListener("click", function () {
        burgerOne.classList.toggle('expand');
        burgerTwo.classList.toggle('expand');
        menu.classList.toggle('expand');
        document.body.classList.toggle('expanding');
      })
    }
  }

  searchBoxes() {
    const boxes = document.querySelectorAll('.search-menu');
    boxes.forEach((box) => {
      const button = box.querySelector('.search-icon');
      const input = box.querySelector('input');
      button.addEventListener('click', function (event) {
        event.preventDefault();
        box.classList.toggle('opened');
        input.focus();
      });
    });
  }

  tabs() {
    var i, tabs = document.querySelectorAll('.tabs');
    for (i = 0; i < tabs.length; i++) {
      new Tabs({
        container: tabs[i]
      });
    }
  }

  lazyLoadVideos() {
    lazyframe('.lazyframe', {
      autoplay: false
    });
  }

  sharingTools() {

    window.shareToMessenger = this.shareToMessenger;
    window.isMobile = this.isMobile;
    window.copyItemUrl = this.copyItemUrl;

    var toolbar = document.querySelector('.sharing-top-sticky');

    if (!toolbar) {
      return;
    }

    window.addEventListener('scroll', (e) => {
      if (window.scrollY >= 600) {
        toolbar.classList.add('visible');
      } else {
        toolbar.classList.remove('visible');
      }
    });

    var toggler = toolbar.querySelector('.share-toggler');

    if (toggler) {
      toggler.addEventListener('click', (e) => {
        e.preventDefault();
        toolbar.classList.toggle('can-share');
      });
    }

  }

  shareToMessenger(url) {
    if (window.isMobile()) {
      window.location.href = 'fb-messenger://share/?app_id=198096357021717&link=' + url;
    } else {
      window.open('https://www.facebook.com/dialog/send?app_id=198096357021717&link=' + url + '&redirect_uri=' + url, '_blank');
    }
  }

  isMobile() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/windows phone/i.test(userAgent)) {
      return true;
    }

    if (/android/i.test(userAgent)) {
      return true;
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return true;
    }

    return false;
  }

  copyItemUrl(element) {

    if (!navigator.clipboard) {
      return;
    }

    const url = element.dataset.url;

    if (!url) {
      return;
    }

    navigator.clipboard.writeText(url).then(
      () => {
        element.classList.add('copied');
        window.setTimeout(() => {
          element.classList.remove('copied');
        }, 5000);

      }, () => {
        console.log('Copy URL failed');
      }
    );

  }

}

export {
  Global as
    default
};
